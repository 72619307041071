<template>
  <v-dialog max-width="600px" v-model="dialog" persistent >
    <v-card class="file-card">
      <v-card-title>Email Firm Members</v-card-title>
      <v-form ref="emailForm" v-model="emailFormValid">
        <v-card-text>
          <div class="font-weight-bold ml-2">Firm Recipients</div>
          <v-select
            v-model="recipients"
            :items="firmMembers"
            label="Firm Members"
            item-text="member_name"
            item-value="id"
            filled
            multiple
            :rules="[recipients.length > 0 || 'Required']"
          >
          </v-select>
        </v-card-text>
      </v-form>
      <v-card-text>
        <h4 class="mb-2">Select Files to Attach</h4>
        <div v-if="fileError" class="red--text">At least one file is required</div>
        <v-container>
          <v-layout column style="max-height: 200px">
            <v-flex style="overflow: auto">
              <v-data-table
                v-model="selectedFiles"
                :headers="headers"
                :items="caseData.files || []"
                :single-select="caseData.files.length < 3"
                item-key="id"
                hide-default-footer
                show-select
              >
                <template #no-data>
                  This case currently has no files. Use the Documents tab to add files.
                </template>
                <template #item.size="{ item }">
                  {{ humanFileSize(item.size) }}
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-text>
        <h4 class="mb-2">Optional Message</h4>
        <v-form ref="form">
          <v-textarea v-model="message" filled label="Message..." />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" @click="close" class="mr-4">
          Cancel
        </v-btn>
        <v-btn color='primary' @click="sendEmail" :loading="loading">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import event, { Events } from '../../event'
import RulesMixin from '@/mixins/RulesMixin'
export default Vue.extend({
  components: {},
  props: {
    caseId: {
      type: Number,
      required: true
    },
    caseData: {
      type: Object,
      required: true
    }
  },
  mixins: [RulesMixin],
  data: () => ({
    dialog: false,
    message: '',
    recipients: [],
    selectedFiles: [],
    loading: false,
    headers: [
      { text: 'File Name', value: 'name' },
      { text: 'File Size', value: 'size', sortable: false }
    ],
    emailFormValid: true,
    firmMembers: []
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    }),
    recipientError () {
      return this.recipients.length === 0
    },
    fileError () {
      return this.selectedFiles.length === 0
    }
  },
  methods: {
    open () {
      this.dialog = true
      this.getCaseMembers()
    },
    close () {
      this.loading = false
      this.selectedFiles = []
      this.recipients = []
      this.message = ''
      this.$refs.emailForm.reset()
      this.dialog = false
    },
    async sendEmail () {
      this.loading = true

      this.$refs.emailForm.validate()
      if (this.recipientError || this.fileError || !this.emailFormValid) {
        this.loading = false
        return
      }

      await this.$axios.post(`${this.url}/case/${this.caseId}/emails`, {
        recipients: this.recipients,
        message: this.message,
        files: this.selectedFiles
      })
        .then(response => {
          if (response.status === 200) {
            event.emit(Events.SUCCESS, 'Email sent successfully', 2000)
            this.$emit('emailSent')
          } else {
            event.emit(Events.ERROR, 'Failed to send email', 2000)
          }
        })
        .catch((error) => {
          this.loading = false
          event.emit(Events.ERROR, error.response.data.payload.message, 2000)
        })
        .finally(() => {
          this.close()
        })
    },
    removeFile (index) {
      Vue.delete(this.selectedFiles, index)
    },
    appendFile (file) {
      this.files.push(file)
    },
    humanFileSize (bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }
      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

      return bytes.toFixed(dp) + ' ' + units[u]
    },
    async getCaseMembers () {
      const response = await window.axios.get(this.url + '/case/' + this.$route.params.caseId + '/members')
      this.firmMembers = response.data.payload.members
    }
  }
})
</script>
