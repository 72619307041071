var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UpdateBillDialog',{ref:"updateBillDialog",attrs:{"case-status":_vm.caseData.status},on:{"getCase":function($event){return _vm.$emit('getCase')}}}),_c('CreateBillDialog',{ref:"createBillDialog",attrs:{"case-status":_vm.caseData.status},on:{"getCase":function($event){return _vm.$emit('getCase')},"dropped":function($event){return _vm.$emit('dropped')}}}),_c('DeleteBillDialog',{ref:"deleteBillDialog",on:{"getCase":function($event){return _vm.$emit('getCase')}}}),_c('UpdateApptActualPriceDialog',{ref:"updateApptActualPriceDialog",on:{"getAppointments":_vm.getCaseAppointments}}),_c('h2',{staticClass:"mb-3"},[_vm._v(" Patient Billing "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","fab":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.$refs.createBillDialog.open()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-dollar-sign")])],1)]}}])},[_c('span',[_vm._v("Create New Bill Record")])])],1),_c('h3',{staticClass:"mt-6 mb-1"},[_vm._v("Current Estimates")]),_c('v-card',{staticClass:"pa-3",attrs:{"flat":"","color":"#efefef"}},[_c('v-data-table',{staticClass:"mb-4",attrs:{"headers":_vm.treatmentHeaders,"items":_vm.appointments,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.treatment.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.treatment.price)+" ")]}},{key:"item.actual_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.actual_price ? (item.actual_price / 100).toLocaleString() : '0.00')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.$refs.updateApptActualPriceDialog.open(item.id)}}},[_vm._v(" "+_vm._s(item.actual_price ? 'Edit Actual Price' : 'Add Actual Price')+" ")])],1)],1)],1)]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"totalColor"},[_c('td',[_c('strong',[_vm._v("Estimated Total")])]),_c('td'),_c('td'),_c('td',[_c('b',[_vm._v("$ "+_vm._s(_vm.estimateTotal))])])])]},proxy:true}])})],1),_c('h3',{staticClass:"mt-12 mb-2"},[_vm._v("Billing Stats")]),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"text-center center",attrs:{"variant":"outlined"}},[_c('v-card-text',{staticClass:"text-h4 font-weight-bold pa-0 pt-3"},[_vm._v(" $ "+_vm._s(_vm.billedTotal)+" ")]),_c('v-card-text',{staticClass:"text-h6 font-weight-bold pa-0 pt-2"},[_vm._v(" Total Billed ")])],1)],1),_c('v-col',[_c('v-card',{staticClass:"text-center center"},[_c('v-card-text',{staticClass:"text-h4 font-weight-bold pa-0 pt-3"},[_vm._v(" $ "+_vm._s(_vm.collectedTotal)+" ")]),_c('v-card-text',{staticClass:"text-h6 font-weight-bold pa-0 pt-2"},[_vm._v(" Total Collected ")])],1)],1),_c('v-col',[_c('v-card',{staticClass:"text-center center"},[_c('v-card-text',{staticClass:"text-h4 font-weight-bold pa-0 pt-3"},[_vm._v(" "+_vm._s(_vm.percentageCollected)+" % ")]),_c('v-card-text',{staticClass:"text-h6 font-weight-bold pa-0 pt-2"},[_vm._v(" Collected ")])],1)],1)],1),_c('h3',{staticClass:"mt-12"},[_vm._v("Billing Records")]),_c('v-data-table',{staticClass:"mt-6",attrs:{"items":_vm.caseData.case_bills,"headers":_vm.billHeaders},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getActionText(item.action))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s((item.amount / 100).toLocaleString())+" ")]}},{key:"item.action_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCarbonToDateNoTime(item.action_date))+" ")]}},{key:"item.recipients",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.recipients ? item.recipients.join(', ') : '')+" ")]}},{key:"item.note",fn:function(){return [_c('td',{staticClass:"d-flex justify-center"},[_c('v-icon',[_vm._v("fa fa-sticky-note")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editRecord(item)}}},[_c('v-list-item-title',[_vm._v("Update Record")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.deleteBillDialog.open(item)}}},[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Delete Record")])],1)],1)],1)]}}])}),(_vm.caseData.hospital)?_c('div',[_c('h4',{staticClass:"mt-4"},[_vm._v("Billing Notes")]),_c('v-list',[(_vm.caseData.hospital)?_c('v-list-item',[_vm._v(" Patient visited ER at "+_vm._s(_vm.caseData.hospital.name)+" ")]):_vm._e()],1)],1):_vm._e(),_c('EmailAttorneyComponent',{ref:"attorneyComponent",attrs:{"case-data":_vm.caseData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }