export const DischargeStatus = {
  PROVIDER: { text: 'Provider', value: 'provider' },
  COMPLIANCE: { text: 'Compliance', value: 'compliance' },
  DROPPED: { text: 'Dropped', value: 'dropped' }
}

export const DischargeOptions = [
  DischargeStatus.PROVIDER,
  DischargeStatus.COMPLIANCE,
  DischargeStatus.DROPPED
]
