export const AppointmentStatus = {
  SCHEDULED: { text: 'Scheduled', value: 'scheduled' },
  MISSED: { text: 'Missed', value: 'missed' },
  REQUESTED: { text: 'Requested', value: 'requested' },
  COMPLETED: { text: 'Completed', value: 'completed' },
  CANCELED: { text: 'Canceled', value: 'canceled' },
  BILLED: { text: 'Billed', value: 'billed' }
}

export const AppointmentStatusOptions = [
  AppointmentStatus.SCHEDULED,
  AppointmentStatus.MISSED,
  AppointmentStatus.REQUESTED,
  AppointmentStatus.COMPLETED,
  AppointmentStatus.CANCELED,
  AppointmentStatus.BILLED
]
