<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>Update Billing Record</v-card-title>
      <v-card-text>
        <CaseBillForm v-if="bill.id" :passed-bill="bill" @updated="reassignBill" ref="caseBillForm"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="updateBill">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import event, { Events } from '@/event'
import CaseBillForm from '@/components/Case/CaseBillForm.vue'

export default {
  name: 'UpdateBillDialog',
  mixins: [RulesMixin],
  components: { CaseBillForm },
  props: {
    caseStatus: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    bill: {}
  }),
  methods: {
    open (bill) {
      this.bill = bill
      this.dialog = true
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.$refs.caseBillForm.reset()
      this.$emit('getCase')
      this.dialog = false
    },
    reassignBill (value) {
      this.bill = value
    },
    async updateBill () {
      this.$refs.caseBillForm.validate()
      if (!this.$refs.caseBillForm.formValid || this.$refs.caseBillForm.dateValid === 'Required') {
        return
      }

      if (typeof (this.bill.amount) === 'string' && this.bill.amount.includes(',')) {
        this.bill.amount = this.bill.amount.replace(',', '')
      }

      try {
        await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/billing/' + this.bill.id, {
          bill: this.bill
        }).then(() => {
          event.emit(Events.SUCCESS, 'Bill record updated successfully', 2000)
          this.$emit('getCase')
          this.reset()
        })
      } catch (error) {
        event.emit(Events.ERROR, 'Bill record could not be updated', 2000)
      }
    }
  }
}
</script>
