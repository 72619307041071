<template>
  <div>
<!--  Dialogs  -->
    <CreateAppointmentDialog ref="createAppointmentDialog" @reload="checkForCaseStatusChange" />
    <UpdateAppointmentDialog ref="updateAppointmentDialog" @reload="getCaseAppointments" />
    <DeleteAppointmentDialog ref="deleteAppointmentDialog" @deletedAppointment="deleteAppointmentChange" />
    <CaseLogDialog ref="caseLogDialog" @getLogs="updateStatus(tempAppointment.appointment, tempAppointment.newStatus)"/>
    <DischargeDialog ref="dischargeDialog" @getAppointments="getCaseAppointments" @discharge="$emit('discharge')"/>
<!--  Patient Schedule  -->
    <h2 class="mb-3">
      Patient Schedule
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn v-if="viewOnly === false" small fab elevation="0" color="primary" v-on="on" @click="$refs.createAppointmentDialog.open(caseData.id, caseData.status)" class="ml-2">
            <v-icon small>fa-calendar-plus</v-icon>
          </v-btn>
        </template>
        <span>Schedule New Appointment</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn v-if="viewOnly === false" small fab elevation="0" color="primary" v-on="on" @click="$refs.dischargeDialog.open($route.params.caseId)" class="ml-6">
            <v-icon small>fa-door-open</v-icon>
          </v-btn>
        </template>
        <span>Discharge</span>
      </v-tooltip>
    </h2>
    <v-alert v-if="nextAppointment && !caseData.discharged" type="info" color="accent" border="left">Next Appointment is set for {{ formatCarbonDateNoSetTime(nextAppointment.date) }} at {{nextAppointment.time}}</v-alert>
    <v-alert v-else-if="nextAppointment && caseData.discharged" type="info" color="error" border="left">Case is Discharged and has Scheduled Appointments</v-alert>
    <v-alert v-else type="warning" border="left">
      No Appointment Set.
      <a v-if="viewOnly === false" class="text-decoration-underline white--text" @click="$refs.createAppointmentDialog.open(caseData.id, caseData.status)">Schedule an Appointment</a>
    </v-alert>
    <v-data-table
      :items="appointments"
      :headers="apptHeaders"
      :footer-props="{
      'items-per-page-options': [5, 10, 15, 25, 50]
    }"
    >
      <template v-slot:item.date="{ item }">
        <div class="truncate">
          {{formatCarbonDateNoSetTime(item.date)}}, {{item.time}}
        </div>
      </template>
      <template v-slot:item.treatment_id="{ item }">
        <div class="truncate">
          {{ treatmentNameFromId(item.treatment_id) }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-menu :disabled="viewOnly">
          <template #activator="{ on }">
            <v-btn depressed class="text-capitalize" v-on="on">
              <v-icon small left :color="statusColor(item.status)">{{ statusIcon(item.status) }}</v-icon>
              {{ item.status}}
            </v-btn>
          </template>
          <v-list v-if="Object.keys(appointmentStatuses(item.status)).length !== 0">
            <v-list-item v-for="status in appointmentStatuses(item.status)" :key="status" @click="mandatoryLog(item, status)">
              <v-list-item-title>{{ status }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.note="">
        <div class="truncate">
          <v-icon>fa fa-sticky-note</v-icon>
        </div>
      </template>
      <template #item.actions="{ item }">
        <v-menu>
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>fas fa-ellipsis-h</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editAppointment(item)">
              <v-list-item-title>Update Appointment</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="red--text" @click="$refs.deleteAppointmentDialog.open(item)">
                Delete Appointment
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import event, { Events } from '../../event'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
import CaseLogDialog from '@/components/Case/CaseLogDialog'
import { AppointmentStatus } from '@/js/case/AppointmentStatus'
import DischargeDialog from '@/components/Case/DischargeDialog'
import CreateAppointmentDialog from '@/components/Case/Dialogs/Appointments/CreateAppointmentDialog.vue'
import UpdateAppointmentDialog from '@/components/Case/Dialogs/Appointments/UpdateAppointmentDialog.vue'
import DeleteAppointmentDialog from '@/components/Case/Dialogs/Appointments/DeleteAppointmentDialog.vue'
import { CaseStatuses } from '@/js/CaseStatuses'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'PatientSchedule',
  components: {
    CreateAppointmentDialog,
    UpdateAppointmentDialog,
    DeleteAppointmentDialog,
    CaseLogDialog,
    DischargeDialog
  },
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    caseData: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    loading: false,
    apptHeaders: [
      { text: 'Date', value: 'date' },
      { text: 'Treatment', value: 'treatment_id' },
      { text: 'Status', value: 'status' },
      { text: 'Notes', value: 'note' },
      { text: 'Quick Actions', value: 'actions' }
    ],
    tempAppointment: {},
    appointments: []
  }),
  computed: {
    ...mapState({
      availableStatuses: state => state.Case.availableAppointmentStatuses
    }),
    ...mapGetters({
      treatmentNameFromId: 'Treatments/treatmentNameFromId',
      appointmentStatuses: 'Case/appointmentStatuses'
    }),
    nextAppointment () {
      const appointments = [...this.appointments]
      const filtered = appointments.filter(appointment => appointment.status === AppointmentStatus.SCHEDULED.value)

      // assumes data is already sorted by date descending
      if (filtered[0]) {
        return filtered[0]
      }

      return null
    }
  },
  mounted () {
    this.getTreatments()
    this.getCaseAppointments()
  },
  methods: {
    formatCarbonDateNoSetTime,
    ...mapActions({
      getTreatments: 'Treatments/getTreatments'
    }),
    checkForCaseStatusChange () {
      this.$emit('statusChange')
      this.getCaseAppointments()
    },
    deleteAppointmentChange () {
      this.$emit('deletedAppointment')
      this.getCaseAppointments()
    },
    async getCaseAppointments () {
      this.$store.dispatch('Case/getCaseAppointments', { caseId: this.$route.params.caseId })
        .then(response => {
          this.appointments = response
        })
    },
    async mandatoryLog (appointment, newStatus) {
      if (newStatus === AppointmentStatus.CANCELED.value || newStatus === AppointmentStatus.MISSED.value) {
        this.tempAppointment.appointment = appointment
        this.tempAppointment.newStatus = newStatus
        this.$refs.caseLogDialog.open()
      } else if (newStatus === AppointmentStatus.COMPLETED.value && this.caseData.status === CaseStatuses.INTAKE.value) {
        await this.updateStatus(appointment, newStatus)
        await this.$store.dispatch('Case/transitionCase', {
          caseId: this.caseData.id,
          transition: CaseStatuses.ACTIVE.value
        })
        this.$emit('statusChange')
      } else {
        await this.updateStatus(appointment, newStatus)
      }
    },
    async updateStatus (appointment, newStatus) {
      const response = await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.caseData.id + '/appointments/' + appointment.id + '/transition', {
        status: newStatus
      })

      if (response.data.payload) {
        appointment.status = newStatus
        event.emit(Events.SUCCESS, 'Appointment status updated')
        this.$emit('statusChange')
      }
      this.tempAppointment = {}
    },
    statusIcon (status) {
      let icon = 'fa-calendar-alt'
      switch (status) {
        case this.availableStatuses.missed:
        case this.availableStatuses.canceled:
          icon = 'far fa-calendar-times'
          break
        case this.availableStatuses.completed:
          icon = 'far fa-calendar-check'
          break
      }

      return icon
    },
    statusColor (status) {
      let icon = 'blue'
      switch (status) {
        case this.availableStatuses.missed:
        case this.availableStatuses.canceled:
          icon = 'red'
          break
        case this.availableStatuses.completed:
          icon = 'green'
          break
      }

      return icon
    },
    editAppointment (appointment) {
      const copyAppointment = structuredClone(appointment)
      copyAppointment.date = this.formatCarbonDateNoSetTime(copyAppointment.date)
      const parts = copyAppointment.time.split(' ')
      copyAppointment.time = parts[0]
      copyAppointment.day = parts[1]
      this.$refs.updateAppointmentDialog.open(this.caseData.id, copyAppointment)
    }
  }
}
</script>
<style>
.truncate {
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
