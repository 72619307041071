<template>
  <div>
    <input type="file" ref="file" id="file" style="display: none;" @change="uploadFile($event.target.files[0])">
    <p>Manually upload a signed lien document. (.pdf)</p>
    <v-btn
      v-if="uploading === false"
      color="primary"
      @click="$refs.file.click()"
    >
      <v-icon left>fa-file-upload</v-icon>
      upload signed lien
    </v-btn>
    <v-progress-linear v-if="uploading" :value="progress" />
  </div>
</template>
<script>
import event, { Events } from '../../event'
import { mapGetters } from 'vuex'

export default {
  props: {
    caseId: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    progress: '',
    uploading: false
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  methods: {
    uploadFile (file) {
      const data = new FormData()
      data.append('file', file)

      this.progress = 0
      this.uploading = true

      const config = {
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
      }

      this.$axios.post(`${this.url}/case/${this.caseId}/lien`, data, config)
        .then(() => {
          event.emit(Events.SUCCESS, 'Lien Uploaded.')
          this.$emit('success')
        })
        .catch(() => {
          this.uploading = false
        })
    }
  }
}
</script>
