<template>
  <div>
    <template v-if="caseLien === null">
      <template v-if="viewOnly">
        <p>
          A lien document has not yet been created.
        </p>
      </template>
      <template v-else>
        <p>
          A lien document has not yet been created, use the link below to create a standard lien and send a
          request to all parties for signatures.
        </p>
        <v-btn color="primary" @click="requestLienSignatures">
          <v-icon left>fa-file-signature</v-icon>
          Request Lien Signatures
        </v-btn>
      </template>
    </template>

    <template v-else-if="!!caseLien.lien_file_id === false && caseLien.status === 'requested'">
      <p>The lien has been sent to all parties for signatures.</p>

      <div id="patient-signature-status" class="d-flex align-center">
        <template v-if="!!caseLien.patient_signature && !!caseLien.patient_signature.signature_file_id">
          <v-icon color="success" class="mr-2">fas fa-circle</v-icon>
          Patient signature received
        </template>

        <template v-else>
          <v-icon class="mr-2">far fa-circle</v-icon>
          Waiting on patient signature
          <v-btn v-if="viewOnly === false" small depressed outlined color="primary" class="ml-2 text-lowercase" @click="resendRequest('patient')">resend request</v-btn>
          <v-btn v-if="viewOnly === false && !caseLien.patient_signature.esign_consent" :href="getSignUrl(caseLien.patient_signature.token)" target="_blank" small depressed outlined color="secondary" class="ml-2 text-lowercase">
            Manually Sign
          </v-btn>
        </template>
      </div>

      <div id="attorney-signature-status" class="d-flex align-center mt-2">
        <template v-if="!!caseLien.attorney_signature && !!caseLien.attorney_signature.signature_file_id">
          <v-icon color="success" class="mr-2">fas fa-circle</v-icon>
          attorney signature received
        </template>

        <template v-else-if="!!caseLien.attorney_signature === false">
          <v-icon class="mr-2">far fa-circle</v-icon>
          <span v-if="caseData.hasAttorney === false">
            No attorney has been assigned to this case
          </span>
          <span v-else>
            Attorney signature request has not been sent
          </span>
        </template>

        <template v-else>
          <v-icon class="mr-2">far fa-circle</v-icon>
          Waiting on attorney signature
          <v-btn v-if="viewOnly === false" small depressed outlined color="primary" class="ml-2 text-lowercase" @click="resendRequest('attorney')">resend request</v-btn>
          <v-btn v-if="viewOnly === false && !caseLien.attorney_signature.esign_consent" :href="getSignUrl(caseLien.attorney_signature.token)" target="_blank" small depressed outlined color="secondary" class="ml-2 text-lowercase">
            Manually Sign
          </v-btn>
        </template>
      </div>
    </template>

    <template v-else>
      <p>Download a copy of the signed lien document</p>
      <v-btn color="primary" @click="download" :loading="downloading">
        <v-icon left>fa-file-download</v-icon>
        Lien
      </v-btn>
    </template>

    <template v-if="viewOnly === false && caseLien && caseLien.status === 'requested'">
      <v-divider class="my-4" />

      <LeanUpload @success="this.getCaseLien" :case-id="caseData.id" />
    </template>

  </div>
</template>
<script>
import event, { Events } from '../../event'
import LeanUpload from './LeanUpload'
import { mapGetters } from 'vuex'
export default {
  name: 'LienTab',
  components: { LeanUpload },
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    },
    caseData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    caseLien: null,
    downloading: false
  }),
  created () {
    this.getCaseLien()
  },
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  methods: {
    getSignUrl (token) {
      return `${process.env.VUE_APP_WEB_URL}/case/${this.caseData.id}/lien/sign?token=${token}`
    },
    download () {
      this.downloading = true
      this.$axios.get(`${this.url}/case/${this.caseData.id}/lien?download=1`, {
        responseType: 'blob'
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: this.caseLien.file.mime })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style.display = 'none'
          a.href = url
          a.download = this.caseLien.file.name
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .finally(() => {
          this.downloading = false
        })
    },
    getCaseLien () {
      this.$store
        .dispatch('Case/getCaseLien', this.caseData.id)
        .then(lien => {
          this.caseLien = lien
        })
    },
    requestLienSignatures () {
      this.$store
        .dispatch('Case/requestLienSignatures', this.caseData.id)
        .then(lien => {
          this.caseLien = lien
        })
    },
    resendRequest (to) {
      this.$store
        .dispatch('Case/resendSignatureRequest', { caseId: this.caseData.id, to })
        .then(response => {
          event.emit(Events.SUCCESS, 'request sent successfully')
        })
    }
  }
}
</script>
