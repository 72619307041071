<template>
  <v-form ref="form" v-model="formValid">
    <v-row dense>
      <v-col>
        <v-select
          v-model="bill.action"
          :items="BillActionOptions"
          label="Bill Action"
          item-text="text"
          item-value="value"
          filled dense
          :rules="[rules.required]"
          :disabled="'id' in bill"
        />
      </v-col>
    </v-row>
<!-- v-show="!!bill.action"   -->
    <div>
      <v-row dense>
        <v-col>
          <date-picker
            ref="datepicker"
            :date="bill.action_date"
            :default-to-today="false"
            label="Date"
            @date-change="setActionDate"
            :dateValid="dateValid"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="bill.amount"
            label="Amount"
            filled prepend-inner-icon="fa-dollar-sign"
            dense
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select
            v-model="bill.company"
            :items="BillCompanyOptions"
            label="Company"
            filled dense
            :rules="[rules.required]"
            @change="checkTreatmentType"
            clearable
          />
        </v-col>
        <v-col>
          <v-select
            v-model="bill.treatment_type"
            :items="BillTreatmentTypeOptions"
            label="Treatment Type"
            filled dense
            :rules="[TreatmentRules]"
            clearable
          />
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col>
            <div class="font-weight-bold">Recipients</div>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn small color="primary" @click="addRecipient">Add Recipient</v-btn>
          </v-col>
        </v-row>
        <div v-show="bill.recipients.length === 0">No Recipients</div>
        <small class="red--text" :class="{hidden: !bill.action || bill.action === BillActions.BAD.value || bill.action === BillActions.DROPPED.value || bill.recipients.length > 0 }">
          Recipients are required
        </small>
        <v-row v-show="bill.recipients.length > 0">
          <v-col cols="6" class="d-flex" v-for="(recipient, index) in bill.recipients" :key="index">
            <v-text-field
              v-model="bill.recipients[index]"
              filled dense
              :rules="[rules.required]"
            />
            <v-icon class="text-h4 red--text mb-6 ml-1" @click="deleteRecipient(index)">mdi-alpha-x-box</v-icon>
          </v-col>
        </v-row>
      </div>
      <div class="mt-6">
        <div class="font-weight-bold">Billing Note</div>
        <v-textarea v-model="bill.note" filled placeholder="Billing note...." :rules="[NoteRules]"/>
      </div>
    </div>
  </v-form>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import { BillActionOptions, BillActions, BillCompanies, BillCompanyOptions, BillTreatmentTypeOptions } from '@/js/case/CaseBills'
import DatePicker from '@/components/PatientIntake/DatePicker'

export default {
  name: 'CaseBillForm',
  mixins: [RulesMixin],
  components: { DatePicker },
  props: {
    passedBill: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    formValid: true,
    bill: {
      action: '',
      action_date: '',
      amount: '',
      company: '',
      treatment_type: '',
      recipients: [],
      note: ''
    },
    dateValid: true,
    BillActionOptions,
    BillActions,
    BillCompanies,
    BillCompanyOptions,
    BillTreatmentTypeOptions
  }),
  computed: {
    TreatmentRules () {
      if (this.bill.company === BillCompanies.POLARIS) {
        return this.rules.required
      }
      return this.rules.optional
    },
    NoteRules () {
      if (this.bill.action === BillActions.BAD.value) {
        return this.rules.required
      }
      return this.rules.optional
    }
  },
  mounted () {
    this.bill = this.passedBill
    this.$nextTick(() => {
      this.$refs.datepicker.checkDate()
    })
  },
  methods: {
    setActionDate (value) {
      this.bill.action_date = value
    },
    addRecipient () {
      if (this.bill.recipients.length === 0) {
        this.bill.recipients = ['']
      } else {
        this.bill.recipients.push('')
      }
    },
    deleteRecipient (index) {
      this.bill.recipients.splice(index, 1)
    },
    validateDate () {
      this.dateValid = true
      if (!this.bill.action_date) {
        this.dateValid = 'Required'
      }
    },
    validate () {
      this.$refs.form.validate()
      this.validateDate()
    },
    reset () {
      this.dateValid = true
      this.$refs.form.reset()
      this.bill = {
        action: '',
        action_date: '',
        amount: '',
        company: '',
        treatment_type: '',
        recipients: [],
        note: ''
      }
      this.$nextTick(() => {
        this.$refs.datepicker.clearInput()
        this.$refs.datepicker.checkDate()
      })
    },
    checkTreatmentType (company) {
      if (company === BillCompanies.POLARIS) {
        return
      }
      this.$nextTick(() => {
        this.$refs.form.validate()
      })
    }
  },
  updated () {
    this.$emit('updated', this.bill)
  }
}
</script>
<style scoped>
.hidden {
  visibility: hidden;
}
</style>
