<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card class="text-center">
      <v-card-title class="d-flex justify-center">Delete Appointment</v-card-title>
      <v-card-text v-if="appointment.id">
        Are you sure you want to delete this appointment?
        <div class="font-weight-medium mt-2">
          <div>ID: {{appointment.id}} </div>
          <div>Status: {{capitalizeFirstWord(appointment.status)}} </div>
          <div>Date: {{formatCarbonToDateNoTime(appointment.date)}} {{appointment.time}}</div>
          <div>Treatment: {{ treatmentNameFromId(appointment.treatment_id) }}</div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn depressed color="error" @click="deleteAppointment">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import DateMixin from '@/mixins/DateMixin'
import { capitalizeFirstWord } from '@/js/functions'
import { mapGetters } from 'vuex'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'DeleteAppointmentDialog',
  mixins: [DateMixin],
  components: {},
  data: () => ({
    dialog: false,
    appointment: {}
  }),
  computed: {
    ...mapGetters({
      treatmentNameFromId: 'Treatments/treatmentNameFromId'
    })
  },
  methods: {
    capitalizeFirstWord,
    open (appointment) {
      this.appointment = structuredClone(appointment)
      this.dialog = true
    },
    cancel () {
      this.appointment = {}
      this.dialog = false
    },
    async deleteAppointment () {
      try {
        await this.$axios.delete(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/appointments/' + this.appointment.id)
          .then(() => {
            this.appointment = {}
            this.$emit('deletedAppointment')
            this.dialog = false
            event.emit(Events.SUCCESS, 'Appointment deleted successfully.')
          })
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    }
  }
}
</script>
