<template>
  <v-dialog v-model="dialog" max-width="550" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title class="d-flex justify-center">In Office Intake</v-card-title>
      <v-card-text>
        <div class="text-center red--text font-weight-bold mb-3">
          Please make sure all required forms have been completed and uploaded.
          An appointment will still need to be completed for case to become Active.
        </div>
        <h4>Select each file that has been uploaded:</h4>
        <div>
          <v-checkbox v-model="forms" label="Medical Release Form" hide-details value="Medical Release Form"/>
          <v-checkbox v-model="forms" label="Privacy Practices Form" hide-details value="Privacy Practices Form"/>
          <v-checkbox v-model="forms" label="Assignment of Benefits Form" hide-details value="Assignment of Benefits Form"/>
          <v-checkbox v-model="forms" label="Northstar Lien Form" hide-details value="Northstar Lien Form"/>
          <v-checkbox v-model="forms" label="Polaris Lien Form" hide-details value="Polaris Lien Form"/>
          <v-checkbox v-model="forms" label="Treatment Referral Form" hide-details value="Treatment Referral Form"/>
          <v-checkbox v-model="forms" label="Patient Intake Form" hide-details value="Patient Intake Form"/>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="confirmIntake" :disabled="forms.length !== 7" :loading="loading">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import DateMixin from '@/mixins/DateMixin'

export default {
  name: 'InOfficeIntakeDialog',
  mixins: [DateMixin],
  components: {},
  data: () => ({
    dialog: false,
    forms: [],
    loading: false,
    caseId: ''
  }),
  methods: {
    open (id) {
      this.caseId = id
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.caseId = ''
      this.forms = []
    },
    async confirmIntake () {
      this.loading = true
      try {
        await this.$store.dispatch('Case/completeInOfficeIntake', this.caseId)
          .then(() => {
            this.reset()
            this.dialog = false
            this.$emit('getCase')
          })
          .finally(() => {
            this.loading = false
          })
        this.reset()
      } catch (error) {
        this.loading = false
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    }
  }
}
</script>
