var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(" Case Logs "),(_vm.viewOnly === false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.$refs.caseLogDialog.open()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-notes-medical")])],1)]}}],null,false,2277942846)},[_c('span',[_vm._v("Create New Log")])]):_vm._e(),_c('CaseLogDialog',{ref:"caseLogDialog",on:{"getLogs":_vm.getCaseLogs}})],1),_c('v-data-table',{staticClass:"mt-6",attrs:{"items":_vm.caseLogs,"headers":_vm.headers,"server-items-length":_vm.totalItems,"page":_vm.requestParams.page,"sort-by":_vm.requestParams.orderBy,"sort-desc":_vm.requestParams.sortDesc,"items-per-page":_vm.requestParams.itemsPerPage,"footer-props":{
                    'items-per-page-options': [5, 10, 25, 50, 100]
                  }},on:{"click:row":function($event){return _vm.$refs.caseLogDialog.open($event)},"update:page":function($event){return _vm.$set(_vm.requestParams, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.requestParams, "orderBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.requestParams, "orderBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.requestParams, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.requestParams, "sortDesc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.requestParams, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.requestParams, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.author",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.name)+" ")]}},{key:"item.created_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCarbonDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCarbonDate(item.updated_at))+" ")]}},{key:"item.log",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.log)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }