export const BillActions = {
  BILLED: { text: 'Billed', value: 'billed' },
  SETTLED: { text: 'Settled', value: 'settled' },
  COLLECTED: { text: 'Collected', value: 'collected' },
  DROPPED: { text: 'Dropped', value: 'dropped' },
  BAD: { text: 'Bad', value: 'bad' }
}

export const BillActionOptions = [
  BillActions.BILLED,
  BillActions.SETTLED,
  BillActions.COLLECTED,
  BillActions.DROPPED,
  BillActions.BAD
]

export const CreateBillStatuses = [
  BillActions.BILLED,
  BillActions.DROPPED,
  BillActions.BAD
]

export const NoTransitionBillStatuses = [
  BillActions.COLLECTED,
  BillActions.BAD
]

export const BillCompanies = {
  NORTHSTAR: 'Northstar Healthcare',
  POLARIS: 'Polaris'
}

export const BillCompanyOptions = [
  BillCompanies.NORTHSTAR,
  BillCompanies.POLARIS
]

export const BillTreatmentTypes = {
  IMAGING: 'Imaging',
  ORTHO: 'Ortho',
  NEURO: 'Neuro',
  PROCEDURES: 'Procedures'
}

export const BillTreatmentTypeOptions = [
  BillTreatmentTypes.IMAGING,
  BillTreatmentTypes.ORTHO,
  BillTreatmentTypes.NEURO,
  BillTreatmentTypes.PROCEDURES
]
