<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="800" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card class="pt-5">
      <v-card-text>
          <MembersForm ref="membersForm" v-if="firm.hasOwnProperty('firm_members')" :passed-firm="firm" @getCase="$emit('getEverything')" @firm="reassignFirm"></MembersForm>
          <v-row>
            <v-col>
              <v-btn @click="cancel">Cancel</v-btn>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn color="primary" @click="saveFirmMembers">
                <v-icon left>fa-save</v-icon>
                Save
              </v-btn>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import RulesMixin from '@/mixins/RulesMixin'
import MembersForm from '@/components/FirmMembers/MembersForm'
import { mapGetters } from 'vuex'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'FirmMembersDialog',
  components: { MembersForm },
  mixins: [RulesMixin],
  props: {
    passedCase: {
      type: Object,
      required: true
    },
    passedFirmMembers: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  data: () => ({
    dialog: false,
    firm: {}
  }),
  methods: {
    open () {
      this.firm = {
        firm_name: structuredClone(this.passedCase.attorney_firm),
        organization_id: structuredClone(this.passedCase.attorney_organization_id),
        firm_members: structuredClone(this.passedFirmMembers)
      }
      this.dialog = true
    },
    cancel () {
      this.firm = {}
      this.dialog = false
    },
    async saveFirmMembers () {
      await this.$refs.membersForm.validate()
      if (!this.$refs.membersForm || (this.$refs.membersForm.firm.firm_members.length > 0 && !this.$refs.membersForm.primaryContact) || !this.$refs.membersForm.validMembers) {
        return
      }

      this.$axios.post(`${this.url}/case/${this.$route.params.caseId}/members`, {
        firm: this.firm
      })
        .then(response => {
          const message = 'Firm Member' + (this.firm.firm_members.length === 1 ? '' : 's') + ' saved successfully'
          this.$emit('getEverything')
          if (this.passedCase.dropped) {
            this.$emit('undropped')
          }
          event.emit(Events.SUCCESS, message)
        })
        .finally(() => {
          this.firm = {}
          this.saving = false
          this.dialog = false
        })
    },
    reassignFirm (value) {
      this.firm = structuredClone(value)
    }
  }
}
</script>
<style>
.hidden {
  visibility: hidden;
}
</style>
