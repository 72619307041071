<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card class="text-center">
      <v-card-title class="d-flex justify-center">Delete Billing Record</v-card-title>
      <v-card-text>
        Are you sure you want to delete this billing record?
        <div class="font-weight-medium mt-2">
          <div>ID: {{bill.id}} </div>
          <div>Status: {{capitalizeFirstWord(bill.action)}} </div>
          <div>Amount: ${{bill.amount / 100}} </div>
          <div>Date: {{formatCarbonToDateNoTime(bill.action_date)}}</div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn depressed color="error" @click="deleteBill">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import DateMixin from '@/mixins/DateMixin'
import { capitalizeFirstWord } from '@/js/functions'

export default {
  name: 'DeleteBillDialog',
  mixins: [DateMixin],
  components: {},
  data: () => ({
    dialog: false,
    bill: {}
  }),
  methods: {
    capitalizeFirstWord,
    open (bill) {
      this.bill = bill
      this.dialog = true
    },
    cancel () {
      this.bill = {}
      this.dialog = false
    },
    async deleteBill () {
      try {
        await this.$axios.delete(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/billing/' + this.bill.id)
          .then(() => {
            this.bill = {}
            this.dialog = false
            this.$emit('getCase')
          })
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    }
  }
}
</script>
