<template>
  <div>
<!-- Dialogs   -->
    <UpdateBillDialog ref="updateBillDialog" @getCase="$emit('getCase')" :case-status="caseData.status"/>
    <CreateBillDialog ref="createBillDialog" @getCase="$emit('getCase')" :case-status="caseData.status" @dropped="$emit('dropped')"/>
    <DeleteBillDialog ref="deleteBillDialog" @getCase="$emit('getCase')" />
    <UpdateApptActualPriceDialog ref="updateApptActualPriceDialog" @getAppointments="getCaseAppointments" />
    <h2 class="mb-3">
      Patient Billing
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn small fab elevation="0" color="primary" v-on="on" class="ml-2" @click="$refs.createBillDialog.open()">
            <v-icon small>fa-dollar-sign</v-icon>
          </v-btn>
        </template>
        <span>Create New Bill Record</span>
      </v-tooltip>
    </h2>

    <h3 class="mt-6 mb-1">Current Estimates</h3>
    <v-card
      flat
      color="#efefef"
      class="pa-3"
    >
      <v-data-table
        :headers="treatmentHeaders"
        :items="appointments"
        disable-sort
        hide-default-footer
        class="mb-4"
      >
        <template #item.treatment.price="{ item }">
          $ {{ item.treatment.price }}
        </template>
        <template #item.actual_price="{ item }">
          $ {{ item.actual_price ? (item.actual_price / 100).toLocaleString() : '0.00' }}
        </template>
        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title @click="$refs.updateApptActualPriceDialog.open(item.id)">
                  {{item.actual_price ? 'Edit Actual Price' : 'Add Actual Price'}}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:body.append>
          <tr class="totalColor">
            <td>
              <strong>Estimated Total</strong>
            </td>
            <td/>
            <td/>
            <td>
              <b>$ {{ estimateTotal }}</b>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

<!-- STATS   -->
    <h3 class="mt-12 mb-2">Billing Stats</h3>
    <v-row>
      <v-col>
        <v-card class="text-center center" variant="outlined">
          <v-card-text class="text-h4 font-weight-bold pa-0 pt-3">
            $ {{billedTotal}}
          </v-card-text>
          <v-card-text class="text-h6 font-weight-bold pa-0 pt-2">
            Total Billed
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center center">
          <v-card-text class="text-h4 font-weight-bold pa-0 pt-3">
            $ {{collectedTotal}}
          </v-card-text>
          <v-card-text class="text-h6 font-weight-bold pa-0 pt-2">
            Total Collected
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center center">
          <v-card-text class="text-h4 font-weight-bold pa-0 pt-3">
            {{percentageCollected}} %
          </v-card-text>
          <v-card-text class="text-h6 font-weight-bold pa-0 pt-2">
            Collected
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
<!-- BILLING RECORDS -->
    <h3 class="mt-12">Billing Records</h3>
    <v-data-table
      :items="caseData.case_bills"
      :headers="billHeaders"
      class="mt-6"
    >
      <template #item.action="{ item }">
        {{ getActionText(item.action) }}
      </template>
      <template #item.amount="{ item }">
        $ {{ (item.amount / 100).toLocaleString()}}
      </template>
      <template #item.action_date="{ item }">
        {{ formatCarbonToDateNoTime(item.action_date)}}
      </template>
      <template #item.recipients="{ item }">
        {{ item.recipients ? item.recipients.join(', ') : ''}}
      </template>
      <template #item.note="">
        <td class="d-flex justify-center">
          <v-icon>fa fa-sticky-note</v-icon>
        </td>
      </template>
      <template #item.actions="{ item }">
        <v-menu>
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>fas fa-ellipsis-h</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editRecord(item)">
              <v-list-item-title>Update Record</v-list-item-title>
            </v-list-item>

            <v-list-item @click="$refs.deleteBillDialog.open(item)">
              <v-list-item-title class="red--text">Delete Record</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <div v-if="caseData.hospital">
      <h4 class="mt-4">Billing Notes</h4>
      <v-list>
        <v-list-item v-if="caseData.hospital">
          Patient visited ER at {{ caseData.hospital.name }}
        </v-list-item>
      </v-list>
    </div>

    <EmailAttorneyComponent ref="attorneyComponent" :case-data="caseData" />
  </div>
</template>
<script>
import DateMixin from '../../mixins/DateMixin'
import { VMoney } from 'v-money'
import { mapGetters } from 'vuex'
import UpdateBillDialog from '@/components/Case/Dialogs/Bills/UpdateBillDialog.vue'
import CreateBillDialog from '@/components/Case/Dialogs/Bills/CreateBillDialog.vue'
import { BillActionOptions, BillActions } from '@/js/case/CaseBills'
import DeleteBillDialog from '@/components/Case/Dialogs/Bills/DeleteBillDialog.vue'
import { AppointmentStatus } from '@/js/case/AppointmentStatus'
import UpdateApptActualPriceDialog from '@/components/Case/Dialogs/Appointments/UpdateApptActualPriceDialog.vue'
import EmailAttorneyComponent from '@/components/Case/EmailAttorneyComponent.vue'

export default {
  directives: { money: VMoney },
  components: { UpdateBillDialog, CreateBillDialog, DeleteBillDialog, UpdateApptActualPriceDialog, EmailAttorneyComponent },
  props: {
    caseData: {
      type: Object,
      required: true
    }
  },
  mixins: [DateMixin],
  data () {
    return {
      saving: false,
      billHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Action', value: 'action' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'action_date' },
        { text: 'Recipients', value: 'recipients' },
        { text: 'Company', value: 'company' },
        { text: 'Note', value: 'note' },
        { text: 'Quick Actions', value: 'actions' }
      ],
      treatmentHeaders: [
        { text: 'Treatment', value: 'treatment.name' },
        { text: 'Price', value: 'treatment.price' },
        { text: 'Actual Price', value: 'actual_price' },
        { text: 'Quick Actions', value: 'actions' }
      ],
      appointments: [],
      apiRecords: null,
      AppointmentStatus
    }
  },
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    }),
    caseValues: {
      get () {
        return this.caseData
      },
      set (updatedValues) {
        this.$emit('updated:caseData', updatedValues)
      }
    },
    estimateTotal () {
      let total = 0

      this.appointments.forEach(appt => {
        if (appt.actual_price) {
          total += parseFloat(appt.actual_price / 100)
        } else {
          total += parseFloat(appt.treatment.price)
        }
      })

      return total.toLocaleString('en-US')
    },
    billedTotal () {
      let total = 0
      this.caseData.case_bills.forEach(bill => {
        if (bill.action === BillActions.BILLED.value) {
          total += parseFloat(bill.amount / 100)
        }
      })
      return total.toLocaleString('en-US')
    },
    collectedTotal () {
      let total = 0
      this.caseData.case_bills.forEach(bill => {
        if (bill.action === BillActions.COLLECTED.value) {
          total += parseFloat(bill.amount / 100)
        }
      })
      return total.toLocaleString('en-US')
    },
    percentageCollected () {
      if (this.billedTotal === '0' || this.collectedTotal === '0') {
        return 0
      }
      return ((parseFloat(this.collectedTotal.replace(',', '')) / parseFloat(this.billedTotal.replace(',', ''))) * 100).toFixed(2)
    }
  },
  methods: {
    editRecord (bill) {
      bill.action_date = this.formatCarbonToDateNoTime(bill.action_date)
      bill.amount = bill.amount / 100
      this.$refs.updateBillDialog.open(bill)
    },
    getActionText (value) {
      const action = BillActionOptions.find(option => option.value === value)
      return action.text
    },
    async getCaseAppointments () {
      const response = await this.$store.dispatch('Case/getCaseAppointments', { caseId: this.$route.params.caseId })
      this.appointments = response.filter((appt) => appt.status === AppointmentStatus.COMPLETED.value)
    }
  },
  async mounted () {
    await this.getCaseAppointments()
  }
}
</script>

<style scoped>
.totalColor {
  background-color:#FFCDD2 !important;
}
</style>
