<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>Create New Billing Record</v-card-title>
      <v-card-text>
        <CaseBillForm :passed-bill="bill" @updated="reassignBill" ref="caseBillForm"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="createBill" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import event, { Events } from '@/event'
import CaseBillForm from '@/components/Case/CaseBillForm.vue'
import CaseBillTransitionMixin from '@/mixins/CaseBillTransitionMixin'
import { BillActions } from '@/js/case/CaseBills'

export default {
  name: 'CreateBillDialog',
  mixins: [RulesMixin, CaseBillTransitionMixin],
  components: { CaseBillForm },
  props: {
    caseStatus: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    bill: {
      action: '',
      action_date: '',
      amount: '',
      company: '',
      treatment_type: '',
      recipients: [],
      note: ''
    },
    loading: false,
    BillActions
  }),
  methods: {
    open () {
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.bill = {
        action: '',
        action_date: '',
        amount: '',
        company: '',
        treatment_type: '',
        recipients: [],
        note: ''
      }
      this.$refs.caseBillForm.reset()
    },
    reassignBill (value) {
      this.bill = value
    },
    async createBill () {
      this.loading = true
      this.$refs.caseBillForm.validate()
      if (!this.$refs.caseBillForm.formValid || this.$refs.caseBillForm.dateValid === 'Required') {
        this.loading = false
        return
      }

      if (this.bill.amount.includes(',')) {
        this.bill.amount = this.bill.amount.replace(',', '')
      }

      try {
        await window.axios.post(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/billing', {
          bill: this.bill
        }).then(async () => {
          await this.checkBillActionTransition()
          event.emit(Events.SUCCESS, 'Bill record created successfully', 2000)
          this.$emit('getCase')
          if (this.bill.action === BillActions.DROPPED.value) {
            this.$emit('dropped')
          }
          this.reset()
          this.dialog = false
        }).finally(() => {
          this.loading = false
        })
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    }
  }
}
</script>
