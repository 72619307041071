<template>
  <div>
    <v-row class="my-4 mt-12">
      <v-col>
        <v-btn color="primary" small  @click="$refs.emailDialog.open()">
          <v-icon small left>
            fa-envelope
          </v-icon>
          Email Firm Members
        </v-btn>
      </v-col>
    </v-row>

    <h4 class="mt-4 mb-2">Emails Sent</h4>
    <h5 class="mb-4 red--text">Download links expire after 14 days</h5>

    <v-data-table
      :headers="emailHeaders"
      :items="emailRecords || []"
      item-key="id"
    >
      <template #no-data>
        No Emails Sent
      </template>
      <template #item.case_member.role="{ item }">
        {{ capitalizeFirstWord(item.case_member.role)}}
      </template>
      <template #item.created_at="{ item }">
        {{ formatCarbonDate(item.created_at)}}
      </template>
    </v-data-table>
    <FilesToEmail ref="emailDialog" @emailSent="getEmailRecords" :case-id="caseData.id" :case-data="caseData"/>
  </div>
</template>
<script>
import FilesToEmail from '@/components/Case/FilesToEmail.vue'
import { formatCarbonDate } from '../../js/PatientIntake/functions'
import { mapGetters } from 'vuex'
import { capitalizeFirstWord } from '@/js/functions'

export default {
  name: 'EmailAttorneyComponent',
  components: { FilesToEmail },
  props: {
    caseData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  data () {
    return {
      emailModal: false,
      emailRecords: [],
      emailHeaders: [
        { text: 'Member Name', value: 'case_member.member_name' },
        { text: 'Role', value: 'case_member.role' },
        { text: 'Attachments', value: 'attachments', sortable: false },
        { text: 'Time Sent', value: 'created_at' }
      ]
    }
  },
  mounted () {
    this.getEmailRecords()
  },
  methods: {
    capitalizeFirstWord,
    formatCarbonDate,
    async getEmailRecords () {
      try {
        await this.$axios
          .get(`${this.url}/case/${this.caseData.id}/emails`)
          .then(response => {
            this.emailRecords = response.data.payload
          })
      } catch (error) { console.log(error) }
    }
  }
}
</script>
