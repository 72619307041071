<template>
  <div v-if="caseData.id && patient.id">
<!-- Dialogs   -->
    <FirmMembersDialog ref="firmMembersDialog" :passedCase="caseData" :passedFirmMembers="caseMembers" @getEverything="getEverything" @undropped="$emit('undropped')"/>
    <InOfficeIntakeDialog ref="inOfficeIntakeDialog" @getCase="getCaseAndPatient"/>
<!-- Patient Info & Firm Members -->
    <v-form ref="form">
      <v-row>
<!-- Patient Info-->
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="10" class="pb-0">
              <v-row>
                <v-col cols="12" sm="6">
                  <h3>Patient First Name:</h3>
                  <v-text-field
                    v-model="patient.first_name"
                    :rules="[rules.required]"
                    filled dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <h3>Patient Last Name:</h3>
                  <v-text-field
                    v-model="patient.last_name"
                    :rules="[rules.required]"
                    filled dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" class="pb-0">
              <v-row>
                <v-col cols="12" sm="6">
                  <h3>Patient DOB:</h3>
                  <DatePicker
                    ref="datepicker"
                    :date="patient.dob"
                    :default-to-today="false"
                    @date-change="setDobDate"
                    :dateValid="dobValid"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <h3>Patient Phone:</h3>
                  <v-text-field
                    v-model="patient.main_phone"
                    :rules="[rules.required, rules.phone]"
                    filled dense
                    v-mask="'(###) ###-####'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" class="pb-0">
              <h3>Patient Email:</h3>
              <v-text-field
                v-model="patient.email"
                :rules="[rules.optionalEmail]"
                filled dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10" class="pb-0">
              <h3>Patient Address:</h3>
              <v-row dense>
                <v-col cols="12" md="8">
                  <v-text-field v-model="patient.address.address_line1"
                                label="* Street Address"
                                filled dense
                                :rules="[rules.required]" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="patient.address.address_line2"
                                label="Address Line 2"
                                filled dense />
                </v-col>
                <v-col cols="12" md="6" xl="4">
                  <v-text-field v-model="patient.address.city"
                                label="* City"
                                filled dense
                                :rules="[rules.required]" />
                </v-col>
                <v-col cols="12" md="6" xl="4">
                  <StatePicker :state="patient.address.state" :rules="[rules.required]" @state-change="setState"/>
                </v-col>
                <v-col>
                  <v-text-field v-model="patient.address.zip"
                                label="* Zip Code"
                                filled dense
                                :rules="[rules.zip, rules.required]" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" class="pb-0">
              <v-row>
                <v-col cols="6">
                  <h3>Accident Date:</h3>
                  <DatePicker
                    ref="datepicker"
                    :date="caseData.date_of_accident"
                    :default-to-today="false"
                    @date-change="setAccidentDate"
                    :dateValid="accidentDateValid"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" class="pb-0">
              <h3>Accident Description</h3>
              <v-textarea
                v-model="caseData.description"
                filled dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="visitedEr" label="Visited Emergency Room?" :disabled="viewOnly" />
              <v-combobox :error-messages="$v.hospitalName.$error ? 'Required' : ''" v-model="hospitalName" filled
                          v-if="visitedEr" :items="hospitalItems" @keydown="lookupHospital" label="Hospital"
                          @keyup="(e) => (this.hospitalName = e.target.value)"
                          @update:search-input="lookupHospital"
                          :disabled="viewOnly"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-divider class="d-md-none mb-4" />
          <v-row>
            <v-col cols="6" md="12">
              <div class="mb-1" v-show="caseData.dropped">
                <div class="d-flex align-content-center">
                  <v-icon color="error">fas fa-exclamation-circle</v-icon>
                  <span class="red--text font-weight-bold ml-2">DROPPED</span>
                </div>
              </div>
              <h3 class="mb-2">{{ caseData.attorney_firm ? caseData.attorney_firm : 'No Legal Firm Assigned' }}</h3>
              <template v-if="caseMembers.length > 0">
                <v-list filled>
                  <v-list-item three-line v-for="member in caseMembers" :key="member.id">
                    <v-list-item-icon>
                      <v-icon small>fa-user</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ member.member_name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ capitalizeFirstWord(member.role) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="member.phone">{{ formatPhone(member.phone) }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ member.member_email }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="member.primary_contact" class="font-weight-bold">Primary Contact</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <span @click="unassignMember(member)" class="unassign-btn" v-show="hasAccess([Roles.ADMIN, Roles.BASIC])">remove</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
              <div v-else>No Firm Members Assigned</div>
            </v-col>
          </v-row>
          <v-btn
            small
            class="mt-4"
            color="primary"
            @click="$refs.firmMembersDialog.open(caseData.attorney_firm, caseData.attorney_organization_id, caseMembers)"
            v-show="hasAccess([Roles.ADMIN, Roles.BASIC])">
            {{caseMembers.length > 0 ? 'Edit Firm Members' : 'Assign Firm Members' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
<!-- Paperwork Status -->
    <v-row class="mb-3 mt-3">
      <v-col>
        <div class="d-flex mb-4">
          <h3>Intake Paperwork Status: </h3>
          <span class="ml-3 mt-1">{{caseData.intake_paperwork_status ? caseData.intake_paperwork_status : IntakePaperworkStatus.NOT_SENT }}</span>
        </div>

        <div class="mt-4" v-if="!!caseData.intake_paperwork_status &&
        caseData.intake_paperwork_status !== IntakePaperworkStatus.NOT_SENT &&
        !caseData.in_office_intake">
          <v-btn @click="viewIntake" class="primary" small>View Intake</v-btn>
          <v-btn
            v-if="caseData.intake_paperwork_status !== IntakePaperworkStatus.COMPLETED"
            class="ml-6"
            small
            color="primary"
            @click="$refs.inOfficeIntakeDialog.open(caseData.id)"
          >In Office Intake
          </v-btn>
        </div>

        <div class="mt-4" v-if="caseData.in_office_intake">
          <span>Intake Completed In Office</span>
        </div>

        <div class="mt-4" v-if="!!caseData.intake_paperwork_status && caseData.intake_paperwork_status !== IntakePaperworkStatus.NOT_SENT">
        </div>
        <div
          class="d-flex"
          v-else-if="caseData.intake_paperwork_status === IntakePaperworkStatus.NOT_SENT || !caseData.intake_paperwork_status"
        >
          <v-btn
            class="d-block" color="primary"
            @click="createInvite"
            :disabled="!patient.main_phone || !patient.dob"
            :loading="saving"
            small
          >Generate Invite Link
          </v-btn>
          <v-btn
            class="ml-6"
            small
            color="primary"
            @click="$refs.inOfficeIntakeDialog.open(caseData.id)"
          >In Office Intake
          </v-btn>
        </div>
        <div class="mt-6" v-if="caseData.intake_paperwork_status === IntakePaperworkStatus.SENT">
          <div class="d-flex">
            <h3>Invite Expires: </h3>
            <span class="mt-1 ml-3">{{formatCarbonWithTime(caseData.users[0].invite.expires)}}</span>
          </div>
          <v-btn
            class="d-block my-4 mr-6" color="primary"
            @click="extendInvite"
            :disabled="!patient.main_phone || !patient.dob"
            :loading="saving"
            small
          >Extend Expiration
          </v-btn>
          <h3 class="d-inline">Invite Link: </h3>
          <v-tooltip top v-model="showToolTip" open-on-click :open-on-hover="false" close-delay="3000">
            <template #activator="{ on, attrs }">
              <span
                class="ml-3"
                v-bind="attrs"
                v-on="on"
                @click="copyInviteLink"
                style="cursor: pointer; user-select: none;"
              ><u>Click to Copy Link</u>
              </span>
            </template>
            <span>Copied!</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col>

      </v-col>
    </v-row>
    <div class="float-right">
        <v-btn @click="savePatient" color="primary" :loading="saving">
          <v-icon small left>
            fa-save
          </v-icon>
          Save Patient
        </v-btn>
    </div>
  </div>
</template>
<script>
import RulesMixin from '@/mixins/RulesMixin'
import DateMixin from '@/mixins/DateMixin'
import PhoneMixin from '@/mixins/PhoneMixin'
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import event, { Events } from '../../event'
import DatePicker from '@/components/PatientIntake/DatePicker'
import { IntakePaperworkStatus } from '@/js/PatientIntake/PaperworkStatus'
import StatePicker from '@/components/FormFields/StatePicker'
import { Roles } from '@/js/Roles'
import { Members } from '@/js/Members'
import FirmMembersDialog from '@/components/FirmMembers/FirmMembersDialog'
import structuredClone from '@ungap/structured-clone'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'
import { capitalizeFirstWord } from '@/js/functions'
import InOfficeIntakeDialog from '@/components/Case/Dialogs/InOfficeIntakeDialog.vue'

export default {
  components: { DatePicker, StatePicker, FirmMembersDialog, InOfficeIntakeDialog },
  mixins: [RulesMixin, DateMixin, PhoneMixin],
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    Roles,
    Members,
    accidentDateValid: true,
    dobValid: true,
    validForm: true,
    IntakePaperworkStatus,
    saving: false,
    hospitals: [],
    hospitalName: '',
    visitedEr: false,
    showToolTip: false,
    caseMembers: [],
    caseData: {},
    patient: {}
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl',
      hasAccess: 'User/hasAccess'
    }),
    hospitalItems () {
      const items = []

      this.hospitals.forEach(i => {
        items.push({
          text: i.name,
          value: i.id
        })
      })

      return items
    }
  },
  watch: {
    visitedEr () {
      if (!this.visitedEr) {
        this.hospitalName = ''
      }
    }
  },
  validations: {
    hospitalName: {
      required: (value, context) => {
        if (!context.visitedEr) {
          return true
        }
        return value !== ''
      }
    }
  },
  mounted () {
    this.getEverything()
  },
  methods: {
    capitalizeFirstWord,
    async getCase () {
      const response = await this.$store.dispatch('Case/getCase', this.$route.params.caseId)
      this.caseData = structuredClone(response)
      this.caseData.date_of_accident = formatCarbonDateNoSetTime(this.caseData.date_of_accident)
    },
    async getPatient () {
      const response = await this.$store.dispatch('Case/getPatient', { caseId: this.caseData.id, patientId: this.caseData.patient_id })
      this.patient = structuredClone(response)
      this.patient.dob = formatCarbonDateNoSetTime(this.patient.dob)
      this.patient.main_phone = this.formatPhone(this.patient.main_phone)
      if (!this.patient.address) {
        this.patient.address = {
          address_line1: '',
          address_line2: '',
          city: '',
          state: '',
          zip: ''
        }
      }
    },
    async getCaseFirmMembers () {
      const response = await this.$store.dispatch('Case/getCaseFirmMembers', this.$route.params.caseId)
      this.caseMembers = structuredClone(response.members)
      this.formatCaseFirmMembers()
    },
    formatHospital () {
      this.visitedEr = this.caseData.hospital !== null

      if (this.visitedEr) {
        this.hospitalName = this.caseData.hospital.name
      }
    },
    lookupHospital: debounce(function (filter) {
      if (filter === null || filter === '') {
        this.hospitals = []
        return
      }

      this.$axios.get(`${this.url}/hospitals?s=${filter}`)
        .then((r) => {
          this.hospitals = r.data.payload
        })
    }, 500),
    unassignMember (member) {
      event.emit(Events.CONFIRM, `Are you sure you want to remove ${member.member_name} from the case?
        They will no longer have access to the case.`, () => {
        // delete the firm member
        const url = `${this.url}/case/${this.caseData.id}/members/${member.id}`
        this.$axios.delete(url)
          .then(response => {
            this.getEverything()
            event.emit(Events.SUCCESS, 'Member removed successfully')
          })
      })
    },
    setDobDate (value) {
      this.patient.dob = value
    },
    setAccidentDate (value) {
      this.caseData.date_of_accident = value
    },
    setState (value) {
      this.patient.address.state = value
    },
    saveHospital () {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      const payload = {
        hospital: this.visitedEr ? this.hospitalName : 0
      }

      if (this.hospitalName.value) {
        payload.hospital = this.hospitalName.value
      }

      this.$axios.post(`${this.url}/case/${this.caseData.id}/hospital`, payload)
    },
    validateForm () {
      this.dobValid = this.validateDate(this.patient, 'dob')
      this.accidentDateValid = this.validateDate(this.caseData, 'date_of_accident')
      this.validForm = this.$refs.form.validate()
      if (!this.validForm || this.dobValid === 'Required' || this.accidentDateValid === 'Required') {
        return false
      }
      return true
    },
    async savePatient () {
      if (!this.validateForm()) {
        return
      }
      this.saving = true
      await this.saveHospital()
      await this.$store.dispatch('Case/updatePatient', { caseId: this.caseData.id, patient: this.patient })
      event.emit(Events.SUCCESS, 'Patient updated successfully')
      this.$emit('patientUpdated')
      this.saving = false
    },
    createInvite () {
      if (!this.validateForm()) {
        return
      }
      this.saving = true
      this.$axios.post(`${this.url}/case/${this.caseData.id}/invite`, {
        patient: this.patient,
        location: this.caseData.location
      }).then(() => {
        this.getCase()
      }).finally(() => {
        this.saving = false
      })
    },
    extendInvite () {
      if (!this.validateForm()) {
        return
      }
      this.saving = true
      this.$axios.put(`${this.url}/case/${this.caseData.id}/invite/${this.caseData.users[0].invite.id}/extend`, {
      }).then(() => {
        this.getCase()
      }).finally(() => {
        this.saving = false
      })
    },
    copyInviteLink () {
      navigator.clipboard.writeText(this.inviteUrl(this.caseData.users[0].invite.token))
      setTimeout(() => {
        this.showToolTip = false
      }, 2000)
    },
    inviteUrl (token) {
      return process.env.VUE_APP_WEB_URL + '/signup/individual/verify?token=' + token
    },
    viewIntake () {
      this.$router.push({ name: 'ClinicIntakeView', params: { uuid: this.patient.uuid, caseId: this.caseData.id } })
    },
    formatCaseFirmMembers () {
      if (this.caseMembers.length === 0) {
        return
      }
      this.caseMembers.map(member => {
        if (member.phone) {
          member.member_phone = this.formatPhone(member.phone)
          return member
        } else {
          member.member_phone = ''
          return member
        }
      })
    },
    async getCaseAndPatient () {
      await this.getPatient()
      await this.getCase()
    },
    async getEverything () {
      await this.getCase()
      await this.getPatient()
      await this.getCaseFirmMembers()
      this.formatHospital()
    }
  }
}
</script>
<style scoped>
.unassign-btn {
  color: red;
  cursor: pointer;
}

.unassign-btn:hover {
  color: #cc0000;
}
</style>
