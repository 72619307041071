<template>
  <v-dialog max-width="800" :value="open" persistent>
    <input type="file" ref="file" id="file" style="display: none;" @change="appendFile($event.target.files[0])">
    <v-card>
      <v-card-text>
        <div style="height: 10px" />
          <v-row v-for="(file, i) in files" :key="i" dense align-content="center">
            <v-col>
              <v-icon v-if="!uploading && !finished" x-small left @click="removeFile(i)">
                fa-times
              </v-icon>
              {{ file.name }}
            </v-col>
            <v-col v-if="uploading || finished" class="text-center">
              <v-progress-linear color="primary" height="10" v-if="uploading && !successIndexes.includes(i) && !failedIndexes.includes(i)" :value="progress[i]" />
              <v-icon
                v-if="successIndexes.includes(i)"
                small
                color="success"
              >
                fas fa-check
              </v-icon>
              <v-icon
                v-if="failedIndexes.includes(i)"
                small
                color="error"
              >
                fas fa-times
              </v-icon>

            </v-col>
          </v-row>
        <div id="upload"
             @click="$refs.file.click()"
             @dragover.prevent
             @drop="onDrop($event)"
             v-if="!uploading && !finished"
        >
          <div>
            Click to Select File or Drop File Here
          </div>
          <div class="mt-10 text--lighten-5">
            .PDF, .DOC, .DOCX, .TXT
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" v-if="!uploading && !finished" :disabled="files.length === 0" @click="upload">
          <v-icon small left>
            fa-upload
          </v-icon>
          Upload
        </v-btn>
        <v-btn :color="finished ? 'success' : 'secondary'" @click="close" v-if="!uploading">
          <v-icon small left>
            fa-times
          </v-icon>
          {{ finished ? 'Close' : 'Cancel' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import event, { Events } from '../../event'

export default Vue.extend({
  props: {
    open: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    caseId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    uploading: false,
    finished: false,
    progress: {},
    files: [],
    successIndexes: [],
    failedIndexes: []
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  methods: {
    close () {
      this.files = []
      this.uploading = false
      this.finished = false
      this.$emit('close')
    },
    removeFile (index) {
      Vue.delete(this.files, index)
    },
    appendFile (file) {
      this.files.push(file)
    },
    uploadFile (file, index) {
      return new Promise(resolve => {
        const data = new FormData()
        data.append('file', file)

        Vue.set(this.progress, index, 0)

        const config = {
          onUploadProgress: progressEvent => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            Vue.set(this.progress, index, progress)
          }
        }

        this.$axios.post(`${this.url}/case/${this.caseId}/files`, data, config)
          .then(response => {
            if (response.data.payload.success.length !== 0) {
              this.successIndexes.push(index)
            } else {
              this.failedIndexes.push(index)
              const fileName = Object.keys(response.data.payload.failed)[0]
              const error = response.data.payload.failed[fileName]
              event.emit(Events.ERROR, `Failed to upload file: ${fileName}: ${error}`)
            }
            resolve(response)
          })
          .catch(() => {
            this.uploading = false
            this.failedIndexes.push(index)
            Promise.reject(new Error('failed to save file'))
          })
      })
    },
    async upload () {
      this.uploading = true
      this.progress = {}
      this.successIndexes = []
      this.failedIndexes = []

      let i = 0
      for (const f of this.files) {
        await this.uploadFile(f, i)
        i++
      }

      this.uploading = false
      this.finished = true

      this.$emit('complete')
    },
    onDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      Object.keys(files).forEach(k => {
        this.appendFile(files[k])
      })
    }
  }
})
</script>

<style lang="scss">
#upload {
  font-size: 1.2rem;
  border: 4px dashed grey;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
}
</style>
