import { BillActions, NoTransitionBillStatuses } from '@/js/case/CaseBills'
import event, { Events } from '@/event'

export default {
  methods: {
    async checkBillActionTransition () {
      // errors when this function is directly in the create bill dialog
      if (NoTransitionBillStatuses.includes(this.caseStatus)) {
        return
      }

      if (this.bill.action === BillActions.DROPPED.value) {
        try {
          await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/dropped')
        } catch (error) {
          event.emit(Events.ERROR, 'Case could not be dropped', 2000)
        }
        return
      }

      if (this.bill.action === this.caseStatus) {
        return
      }

      try {
        await this.$store.dispatch('Case/transitionCase', {
          caseId: this.$route.params.caseId,
          transition: this.bill.action
        })
      } catch (error) {
        event.emit(Events.ERROR, 'Case could not transition to ' + this.bill.action, 2000)
      }
    }
  }
}
