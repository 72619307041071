<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <div>
        <v-card-title>Scheduled Appointments</v-card-title>
        <v-card-text class="my-4">
          <v-data-table
            :items="appointments"
            :headers="headers"
            :hide-default-footer="true"
          >
            <template slot="no-data">
              No Scheduled Appointments
            </template>
            <template v-slot:item.date="{ item }">
              {{formatCarbonDateNoSetTime(item.date)}}, {{item.time}}
            </template>
            <template v-slot:item.treatment_id="{ item }">
              {{ treatmentNameFromId(item.treatment_id) }}
            </template>
            <template v-slot:item.cancel="{ item }">
              <v-btn small color="error" @click="cancelAppointment(item.id)" :loading="loading">Cancel</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" @click="cancelAllAppointments" :loading="loading" v-show="appointments.length > 0">Cancel All Appointments</v-btn>
        </v-card-actions>
      </div>
      <v-card-title>Discharge Case</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <div>Discharged Because:</div>
          <v-select
            v-model="discharge.because"
            :items="DischargeOptions"
            filled dense
            :rules="[rules.required]"
          />
          <div>Discharge Note</div>
          <v-textarea v-model="discharge.note" filled placeholder="....." />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel" :loading="loading">Cancel</v-btn>
        <v-btn color="primary" @click="dischargeCase" :loading="loading">Discharge</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import event, { Events } from '../../event'
import RulesMixin from '../../mixins/RulesMixin'
import { DischargeOptions } from '@/js/DischargeTypes'
import event, { Events } from '@/event'
import { AppointmentStatus } from '@/js/case/AppointmentStatus'
import { mapGetters } from 'vuex'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'

export default {
  name: 'DischargeDialog',
  mixins: [RulesMixin],
  data: () => ({
    formValid: true,
    dialog: false,
    discharge: {},
    DischargeOptions,
    caseId: null,
    appointments: [],
    headers: [
      { text: 'Date', value: 'date' },
      { text: 'Treatment', value: 'treatment_id' },
      { text: 'Location', value: 'location.name' },
      { text: 'Cancel Appt', value: 'cancel' }
    ],
    loading: false
  }),
  computed: {
    ...mapGetters({
      treatmentNameFromId: 'Treatments/treatmentNameFromId',
      url: 'Organization/apiUrl'
    })
  },
  methods: {
    formatCarbonDateNoSetTime,
    open (id) {
      this.caseId = id
      this.dialog = true
      this.getScheduledAppointments()
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.caseId = null
      this.dialog = false
      this.discharge = {}
      this.appointments = []
      this.$refs.form.reset()
    },
    async dischargeCase () {
      this.loading = true
      this.$refs.form.validate()
      if (!this.formValid) {
        this.loading = false
        return
      }
      try {
        await window.axios.post(this.url + '/case/' + this.caseId + '/discharge', {
          discharge: this.discharge
        })
        await this.$store.dispatch('Case/transitionCase', {
          caseId: this.caseId,
          transition: 'discharged'
        })
        this.loading = false
        this.reset()
        this.$emit('discharge')
        event.emit(Events.SUCCESS, 'Case Discharged successfully.')
      } catch (error) {
        this.loading = false
        if (error.response.data.payload.status === 422) {
          return
        }
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    async cancelAppointment (appointmentId) {
      this.loading = true
      try {
        await window.axios.put(this.url + '/case/' + this.caseId + '/appointments/' + appointmentId + '/transition', {
          status: AppointmentStatus.CANCELED.value,
          discharge: true
        }).then(() => {
          this.getScheduledAppointments()
          this.$emit('getAppointments')
        })
      } catch (error) {
        if (error.response.data.payload.status === 422) {
          return
        }
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
      this.loading = false
    },
    async cancelAllAppointments () {
      this.loading = true
      try {
        await window.axios.put(this.url + '/case/' + this.caseId + '/appointments/cancel-all')
          .then(() => {
            this.getScheduledAppointments()
            this.$emit('getAppointments')
          })
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    getScheduledAppointments () {
      this.$store.commit('loading', true)
      this.$store.dispatch('Case/getCaseAppointments', { caseId: this.caseId, params: { status: AppointmentStatus.SCHEDULED.value } })
        .then(response => {
          this.appointments = response
        })
      this.$store.commit('loading', false)
      event.emit(Events.LOADING, false)
    }
  }
}
</script>
