<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>{{title}} Log</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-textarea v-model="log" filled placeholder="Log..." :rules="[rules.required]" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn v-if="!logId" depressed color="primary" @click="createLog">Save</v-btn>
        <v-btn v-if="logId" depressed color="primary" @click="updateLog">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import event, { Events } from '../../event'
import RulesMixin from '../../mixins/RulesMixin'
export default {
  name: 'CaseLogDialog',
  mixins: [RulesMixin],
  data: () => ({
    formValid: true,
    dialog: false,
    title: '',
    log: '',
    logId: null
  }),
  methods: {
    open (log) {
      this.dialog = true
      if (log) {
        this.log = log.log
        this.logId = log.id
        this.title = 'Update'
      } else {
        this.title = 'Create New'
      }
    },
    cancel () {
      this.reset()
    },
    reset () {
      this.log = ''
      this.logId = null
      this.title = ''
      this.formValid = true
      this.$refs.form.reset()
      this.dialog = false
    },
    async createLog () {
      this.$refs.form.validate()
      if (!this.formValid) {
        return
      }
      await window.axios
        .post(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/logs', {
          log: this.log
        })
      // this $emit use on case logs tab & on patient schedule tab
      this.$emit('getLogs')
      event.emit(Events.SUCCESS, 'Case Log added successfully')
      // this.dialog = false
      this.reset()
    },
    async updateLog () {
      this.$refs.form.validate()
      if (!this.formValid) {
        return
      }
      await window.axios
        .put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/logs/' + this.logId, {
          log: this.log
        })
      this.$emit('getLogs')
      event.emit(Events.SUCCESS, 'Case Log updated successfully')
      // this.dialog = false
      this.reset()
    }
  }
}
</script>
