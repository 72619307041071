<template>
  <div>
    <h2>
      Case Logs
      <v-tooltip v-if="viewOnly === false" top>
        <template #activator="{ on }">
          <v-btn small fab elevation="0" color="primary" v-on="on" @click="$refs.caseLogDialog.open()">
            <v-icon small>fa-notes-medical</v-icon>
          </v-btn>
        </template>
        <span>Create New Log</span>
      </v-tooltip>
      <CaseLogDialog ref="caseLogDialog" @getLogs="getCaseLogs"/>
    </h2>
    <v-data-table
      :items="caseLogs"
      :headers="headers"
      class="mt-6"
      @click:row="$refs.caseLogDialog.open($event)"
      :server-items-length="totalItems"
      :page.sync="requestParams.page"
      :sort-by.sync="requestParams.orderBy"
      :sort-desc.sync="requestParams.sortDesc"
      :items-per-page.sync="requestParams.itemsPerPage"
      :footer-props="{
                      'items-per-page-options': [5, 10, 25, 50, 100]
                    }"
    >
      <template #item.author="{ item }">
        {{ item.author.name }}
      </template>
      <template #item.created_at="{ item }">
        {{ formatCarbonDate(item.created_at) }}
      </template>
      <template #item.updated_at="{ item }">
        {{ formatCarbonDate(item.updated_at) }}
      </template>
      <template #item.log="{ item }">
        <div class="truncate">
          {{item.log}}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CaseLogDialog from './CaseLogDialog'
import { formatCarbonDate } from '@/js/PatientIntake/functions'

export default {
  name: 'CaseLogs',
  components: { CaseLogDialog },
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.getCaseLogs()
  },
  data: () => ({
    requestParams: {
      page: 1,
      itemsPerPage: 10,
      orderBy: 'id',
      sortDesc: false
    },
    headers: [
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Author', value: 'author' },
      { text: 'Log', value: 'log' }
    ],
    caseLogs: [],
    totalItems: 0
  }),
  methods: {
    formatCarbonDate,
    getCaseLogs () {
      this.$store.dispatch('Case/getCaseLogs', { caseId: this.$route.params.caseId, params: this.requestParams })
        .then(response => {
          this.caseLogs = response.logs
          this.totalItems = response.total
        })
    }
  },
  watch: {
    requestParams: {
      handler: function () {
        this.getCaseLogs()
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.truncate {
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
