<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>Update Actual Price</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-text-field
            v-model="actual_price"
            label="Actual Price"
            filled prepend-inner-icon="fa-dollar-sign"
            dense
            :rules="[rules.required]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed color="primary" @click="updateActualPrice" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import event, { Events } from '@/event'

export default {
  name: 'UpdateApptActualPriceDialog',
  mixins: [RulesMixin],
  components: {},
  data: () => ({
    dialog: false,
    actual_price: '',
    loading: false,
    appointmentId: 0,
    formValid: true
  }),
  methods: {
    open (id) {
      this.appointmentId = id
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.actual_price = ''
      this.appointmentId = 0
    },
    async updateActualPrice () {
      this.loading = true
      this.$refs.form.validate()

      if (!this.formValid) {
        this.loading = false
        return
      }

      if (this.actual_price.includes(',')) {
        this.actual_price = this.actual_price.replace(',', '')
      }

      try {
        await window.axios.put(this.$store.getters['Organization/apiUrl'] + '/case/' + this.$route.params.caseId + '/appointments/' + this.appointmentId + '/actual-price', {
          actual_price: this.actual_price
        }).then(async () => {
          event.emit(Events.SUCCESS, 'Actual price updated successfully', 2000)
          this.$emit('getAppointments')
          this.reset()
          this.dialog = false
        }).finally(() => {
          this.loading = false
        })
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    }
  }
}
</script>
