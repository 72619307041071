var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CreateAppointmentDialog',{ref:"createAppointmentDialog",on:{"reload":_vm.checkForCaseStatusChange}}),_c('UpdateAppointmentDialog',{ref:"updateAppointmentDialog",on:{"reload":_vm.getCaseAppointments}}),_c('DeleteAppointmentDialog',{ref:"deleteAppointmentDialog",on:{"deletedAppointment":_vm.deleteAppointmentChange}}),_c('CaseLogDialog',{ref:"caseLogDialog",on:{"getLogs":function($event){return _vm.updateStatus(_vm.tempAppointment.appointment, _vm.tempAppointment.newStatus)}}}),_c('DischargeDialog',{ref:"dischargeDialog",on:{"getAppointments":_vm.getCaseAppointments,"discharge":function($event){return _vm.$emit('discharge')}}}),_c('h2',{staticClass:"mb-3"},[_vm._v(" Patient Schedule "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.viewOnly === false)?_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","fab":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.$refs.createAppointmentDialog.open(_vm.caseData.id, _vm.caseData.status)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-calendar-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Schedule New Appointment")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.viewOnly === false)?_c('v-btn',_vm._g({staticClass:"ml-6",attrs:{"small":"","fab":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.$refs.dischargeDialog.open(_vm.$route.params.caseId)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-door-open")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Discharge")])])],1),(_vm.nextAppointment && !_vm.caseData.discharged)?_c('v-alert',{attrs:{"type":"info","color":"accent","border":"left"}},[_vm._v("Next Appointment is set for "+_vm._s(_vm.formatCarbonDateNoSetTime(_vm.nextAppointment.date))+" at "+_vm._s(_vm.nextAppointment.time))]):(_vm.nextAppointment && _vm.caseData.discharged)?_c('v-alert',{attrs:{"type":"info","color":"error","border":"left"}},[_vm._v("Case is Discharged and has Scheduled Appointments")]):_c('v-alert',{attrs:{"type":"warning","border":"left"}},[_vm._v(" No Appointment Set. "),(_vm.viewOnly === false)?_c('a',{staticClass:"text-decoration-underline white--text",on:{"click":function($event){return _vm.$refs.createAppointmentDialog.open(_vm.caseData.id, _vm.caseData.status)}}},[_vm._v("Schedule an Appointment")]):_vm._e()]),_c('v-data-table',{attrs:{"items":_vm.appointments,"headers":_vm.apptHeaders,"footer-props":{
      'items-per-page-options': [5, 10, 15, 25, 50]
    }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.formatCarbonDateNoSetTime(item.date))+", "+_vm._s(item.time)+" ")])]}},{key:"item.treatment_id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.treatmentNameFromId(item.treatment_id))+" ")])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"disabled":_vm.viewOnly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize",attrs:{"depressed":""}},on),[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.statusColor(item.status)}},[_vm._v(_vm._s(_vm.statusIcon(item.status)))]),_vm._v(" "+_vm._s(item.status)+" ")],1)]}}],null,true)},[(Object.keys(_vm.appointmentStatuses(item.status)).length !== 0)?_c('v-list',_vm._l((_vm.appointmentStatuses(item.status)),function(status){return _c('v-list-item',{key:status,on:{"click":function($event){return _vm.mandatoryLog(item, status)}}},[_c('v-list-item-title',[_vm._v(_vm._s(status))])],1)}),1):_vm._e()],1)]}},{key:"item.note",fn:function(){return [_c('div',{staticClass:"truncate"},[_c('v-icon',[_vm._v("fa fa-sticky-note")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editAppointment(item)}}},[_c('v-list-item-title',[_vm._v("Update Appointment")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"red--text",on:{"click":function($event){return _vm.$refs.deleteAppointmentDialog.open(item)}}},[_vm._v(" Delete Appointment ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }